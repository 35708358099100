@tailwind base;
@tailwind components;
@tailwind utilities;
@import "@radix-ui/colors/mauve.css";

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  /* background-color: #F9FAFB; */
}

.menucardShadow {
  box-shadow: 0px 9px 10px 0px rgba(0, 60, 106, 0.07);
}

a {
  color: inherit;
  text-decoration: none;
}

.content-subtitle {
  font-size: 15px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0px;
}

.linea {
  border: 0;
  border-top: 1px solid black; /* Puedes cambiar el color y el grosor según necesites */
  margin: 20px 0; /* Puedes ajustar el margen según necesites */
}

.content-title {
  font-size: 32px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0.5px;
  font-family: "Outfit", sans-serif;
  align-content:center ;
}

* {
  box-sizing: border-box;
}

.noBorder {
  border: 0px solid;
}

.border {
  border: 2px solid #478bc2;
}

.cardIcon {
  height: 40%;
  width: 1.7rem;
}

.cardIconBuild {
  height: 40%;
  width: 2.4rem;
}

.cardIconNavBar {
  width: 1.5rem;
  margin-bottom: 10px;
}

.smallTextFieldFont {
  border: 1px solid #777777;
  border-radius: 10px;
}

.smallInputFont {
  font-size: 0.8rem;
  color: #777777;
  font-family: Poppins-Regular;
}

.datePicker {
  width: 100%;
  padding-left: 15px;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  height: 2.65rem;
  font-size: 1.1rem;
}

.react-datepicker-wrapper {
  width: 100%;
}

.numberField {
  width: 100%;
  border: 1px solid #bdbdbd;
  border-radius: 4px;
  height: 2.5rem;
}

.ingresos {
  padding-left: 28px !important;
}

.DialogOverlay {
  background-color: #928f8f1e;
  position: fixed;
  inset: 0;
  animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.DialogContent {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  height: 200px;
  max-width: 600px;
  max-height: 85vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
}

.Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1;
  font-weight: 500;
  height: 35px;
}

.Button.mauve {
  background-color: var(--mauve4);
  color: var(--mauve11);
}
.Button.mauve:hover {
  background-color: var(--mauve5);
}
.Button.mauve:focus {
  box-shadow: 0 0 0 2px var(--mauve7);
}

.DialogContent:focus {
  outline: none;
}

.tipo-cedula .css-319lph-ValueContainer {
  padding-top: 3px;
  padding-bottom: 3px;
}

.css-1nmdiq5-menu {
  z-index: 3 !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__close-icon {
  margin-right: 30px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.input-button {
  width: 160px;
  height: 43px;
  background: #97d700;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
}

.react-datepicker__close-icon::after {  
  margin-right: 30px;
  background-color: #9ca3af !important;
}

.required-field {
  color: red;
  margin-left: 4px;
}